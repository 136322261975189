import React from 'react';
import * as Icon from 'react-feather';
import { Link } from "gatsby";

const Features = () => {
    return (
        <div className="boxes-area">
			<div className="container">
				<div className="row justify-content-center">
					<div className="col-lg-3 col-sm-6">
						<div className="single-box">
							<div className="icon">
                                <Icon.Server />
							</div>

							<h3>
									Zero Configuration
							</h3>

							<p>Nos offres sont clé en main, toutefois, notre panel vous aide si vous souhaitez changer la configuration de votre seerveur.</p>
						</div>
					</div>

					<div className="col-lg-3 col-sm-6">
						<div className="single-box bg-f78acb">
							<div className="icon">
                                <Icon.Code />
							</div>

							<h3>
									Sécurité accrue
							</h3>

							<p>Nos serveurs sont optimisés et monitorés en permanence. Nos environnements sont cloisonnés et dédiés.</p>
						</div>
					</div>

					<div className="col-lg-3 col-sm-6">
						<div className="single-box bg-eb6b3d">
							<div className="icon">
                                <Icon.GitBranch />
							</div>

							<h3>
									Sauvegarde auto
							</h3>

							<p>Les sauvegardes sont incluses directement dans nos offres et sont gratuites. Elles sont effectuées chaque nuit.</p>
						</div>
					</div>
				</div>
			</div>
		</div>
    )
}

export default Features;
